import request from "@/utils/request";
import { serverUrl } from "@/utils/request";

export const baseURL = serverUrl;
// 获取汽车表
export function getList(query) {
  return request({
    url: `/oa/car/getList`,
    method: "GET",
    params: query
  });
}
// 获取汽车表,无分页汽车审批使用
export function getExList() {
  return request({
    url: `/oa/car/getExList`,
    method: "GET"
  });
}
// 获取车辆使用情况
export function getReport(query) {
  return request({
    url: `/oa/car/getReport`,
    method: "GET",
    params: query
  });
}
//根据id获取单条;
export function getId(carId) {
  return request({
    url: `/oa/car/getId`,
    method: "GET",
    params: { carId }
  });
}
// 新建车辆
export function addCar(car) {
  return request({
    url: `/oa/car/addCar`,
    method: "POST",
    data: car
  });
}
// 修改车辆
export function updateCar(car) {
  return request({
    url: `/oa/car/updateCar`,
    method: "PUT",
    data: car
  });
}
// 删除汽车
export function deleteCar(carId) {
  return request({
    url: `/oa/car/deleteCar`,
    method: "DELETE",
    params: { carId }
  });
}
// 修改车辆状态
export function updateCarState(carId) {
  return request({
    url: `/oa/car/updateCarState`,
    method: "PUT",
    params: { carId }
  });
}
